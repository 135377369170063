<template>
  <div
    id="app"
    :class="classes">
    <!-- Connectivity -->
    <ui-connectivity />

    <!-- Loading -->
    <section
      class="app-loader"
      ref="app-loader"
      v-if="isLoading && !isErrored">
      <div class="app-loader__body">
        <a
          class="app-loader__cta"
          href="https://saylcloud.com/resto"
          target="_blank">
          <ui-img
            alt="Sayl Resto Logo"
            class="app-loader__logo"
            :src="logo"
          />
        </a>

        <div class="app-loader__loader">
          <div class="app-loader__bara"></div>
          <div class="app-loader__barb"></div>
          <div class="app-loader__barc"></div>
          <div class="app-loader__bard"></div>
          <div class="app-loader__bare"></div>
          <div class="app-loader__barf"></div>
        </div>
      </div>
    </section>

    <!-- Loading Error -->
    <error-view
      :errors="errors"
      v-if="isErrored" 
    />

    <!-- Application -->
    <template v-if="status == states.APPLICATION_COMPLETE">
      <!-- Header -->
      <nav-header />
      <nav-burger />
      <nav-admin />

      <!-- Content -->
      <router-view />

      <!-- Footer -->
      <nav-footer />

      <modal-custom />

      <modal-age-disclaimer
        :visible="showAgeDisclaimer"
        @confirm="() => key++"
        v-if="showAgeDisclaimer"
      />

      <modal-idle v-if="isModeTt" />

      <!-- <modal-cookies /> -->
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ModalAgeDisclaimer from '@/components/ui/modal-age-disclaimer'
import ModalCookies from '@/components/ui/modal-cookies'
import ModalCustom from '@/components/ui/modal-custom'
import ModalIdle from '@/components/ui/modal-idle'

import NavBurger from '@/components/navigations/burger'
import NavHeader from '@/components/navigations/header'
import NavFooter from '@/components/navigations/footer'
import NavAdmin from '@/components/navigations/admin-pin'

import UiConnectivity from '@/components/ui/connectivity'
import UiErrorTable from '@/components/ui/error-table'
import UiImg from '@/components/ui/img'

import ErrorView from '@/modules/errors/error'
import Config from '@/config'

export default {
  name: "SaylFront",

  inject: ['$embed', '$localStorage'],

  components: {
    ModalAgeDisclaimer,
    ModalCookies,
    ModalCustom,
    ModalIdle,

    NavAdmin,
    NavBurger,
    NavHeader,
    NavFooter,

    UiConnectivity,
    ErrorView,
    UiErrorTable,
    UiImg
  },

  data() {
    return {
      errorEatin: false,
      key: 1
    }
  },

  computed: {
    ...mapState({
      errors: state => state.bootstrap.errors,
      states: state => state.bootstrap.states,
      status: state => state.bootstrap.status,
      bootstrap: state => state.bootstrap.data,
    }),

    classes() {
      return {
        '-is-loading': this.isLoading,
        '-is-active': !this.isLoading,
        '-is-restricted': this.showAgeDisclaimer
      }
    },

    isLoading() {
      return [this.states.APPLICATION_INIT, this.states.APPLICATION_AUTH, this.states.APPLICATION_MODULES].includes(this.status);
    },

    isErrored() {
      return this.errors && this.errors.length > 0
    },

    isErroredEatin() {
      return this.errorEatin;
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin';
    },

    logo() {
      return Config.cdn + 'statics/images/logos/sayl/color.svg'
    },

    showAgeDisclaimer() {
      let value = window.sessionStorage.getItem('ageDisclaimer')
      
      if(this.$basil.isNil(value)) {
        value = !this.$basil.get(this.$embed, 'embed.model.ageDisclaimer', false)
      }

      return !this.isLoading && this.key && (value === false || this.$basil.isNil(value))
    },
  },

  methods: {
    resize() {
      setTimeout(() => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }, 250)
    },
  },

  mounted() {
    this.$bus.$on('table:null', (value) => this.errorEatin = value)
    this.resize()
    window.addEventListener('resize', this.resize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }
}
</script>

export default () => {
  let ret = [
    {
      path: `/checkout/cart`,
      name: 'sayl-front-checkout.cart',
      component: () => import('./cart'),
      meta: {
        bodyClass: 'view-checkout-cart',
        scrollTop: true
      }
    },

    {
      path: `/checkout/review`,
      name: 'sayl-front-checkout.review',
      component: () => import('./review'),
      meta: {
        bodyClass: 'view-checkout-review',
        footer: false,
        scrollTop: true
      }
    },

    {
      path: `/checkout/confirm/:id`,
      name: 'sayl-front-checkout.confirm',
      component: () => import('./confirm'),
      meta: {
        bodyClass: 'view-checkout-confirm',
        scrollTop: true,
        // header: false
      }
    },

    {
      path: '/feedback/:token',
      name: 'sayl-front-checkout.feedback',
      component: () => import('./feedback'),
      meta: {
        bodyClass: 'view-checkout-feedback',
        scrollTop: true,
        // footer: false,
      }
    },
  ]

  return ret
}

export default {
  namespaced: true,

  name: 'sayl-front.catalog.multishop',

  actions: {
    setShop: async ({ commit, state }, { shop, service, $core, locale = 'en_GB', force }) => {
      state.loading = true
      commit('setShop', shop)

      if(force) {
        state.catalogs = []
      }

      if(shop) {
        let ser = shop.services.find(s => s.name === service)
        let cat = state.catalogs.find(c => c.catalog.id === ser.menuId)

        if(!cat || force) {
          try{
            cat = await $core.catalog.catalog.fetch({ id: ser.menuId, locale: locale, service: ser.name })
          } catch(e) {
            cat = null
            $console.error(e)
          }

          if(cat) {
            state.catalogs.push(cat)
          }
        }

        if(cat) {
          await $core.catalog.init({ catalog: cat, service: ser.name, enrich: 1 })
          commit('setCatalog', cat)
        }
        state.loading = false
        state.subshopKey = state.subshopKey + 1
        return
      }

      state.loading = false
      commit('setCatalog', null)
      state.subshopKey = state.subshopKey + 1
    }
  },

  mutations: {
    setShops: (state, shops) => {
      state.shops = shops
      state.shopsLoading = false
    },
    setCatalog: (state, catalog) => state.catalog = catalog,

    setShop: (state, shop) => state.shop = shop,
  },

  state: {
    loading: true,

    shopsLoading: true,

    shop: null,
    shops: null,

    catalog: null,
    catalogs: [],

    subshopKey: 1,
  },
}

<template>
  <div class="app-loader__error">
    <ui-error 
      actionLabel="Let me try again"
      @action="reload"
      class="ui-error-en"
      subtitle="There's seems to be a problem loading the menu."
      title="Oops"
      />
      
    <div class="group">
      <ui-error 
        @action="reload"
        actionLabel="Probeer opnieuw"
        actionSize="s"
        appearance="secondary"
        class="ui-error-nl"
        :header="false"
        subtitle="Er lijkt een probleem te zijn met het laden van de menukaart."
        title="Hmm"
        />
      <ui-error 
        @action="reload"
        actionLabel="Réessayer"
        actionSize="s"
        appearance="secondary"
        class="ui-error-fr"
        :header="false"
        subtitle="Un problème est survenu lors du chargement du menu"
        title="Oups"
        />
    </div>
  </div>
</template>

<script>
import UiError from '@/components/ui/error'

export default {
  name: 'BootstrapErrorView',
  
  components: {
    UiError
  },

  methods:{
    reload(){
      document.location.reload();
    }
  },
}
</script>

import { default as Config, isDevelopment } from '@/config'

export default ({ args, core, logger, store, transports, Vue }) => {
  return new Promise((resolve, reject) => {
    logger.group('bootstrap.front.core');

    transports.getByName = (name) => {
      return transports[name]
    }

    store.dispatch('bootstrap/getConfig')
    .then(bootstrap => {
      args.embed = Config.embedId

      if (isDevelopment() && !Config.embedId) {
        // Val Loyalty embed
        // args.embed = '61a25bd6dff1eb05312e7ab3'

        // Conn3ct store embed
        // args.embed = '625e959a553bed1af631c093'

        // Casa di mama embed
        args.embed = '5eccefa6e92aa456ed14d372'

        // Multishop
        // args.embed = '64214e50c196d0096f059481'

        let s = document.createElement('script')
        s.innerHTML = `(function (c, o, nn, e, _c, t) {
            (c[e] = c[e] || function (f, v) { c[e][f] = v }), (c[e].t = 1 * new Date())
            let s = o.createElement('script')
            s.src = nn
            o.body.appendChild(s)
          })(window, document, 'https://cdn-apps.sayl.cloud/staging/conn3ct-wallet-bootstrap/latest/wallet.min.js', 'conn3ct')`
        document.body.appendChild(s)
      }

      core.init({ args, bootstrap })
        .then(() => {
          document.title = core.embed.shop.model.name

          // if(isDevelopment()) {
          //   let style = document.createElement('style')
          //   style.innerHTML = core.embed.embed.model.customCss
          //   document.head.append(style)
          // }

          store.getters['bootstrap/oat']
          resolve()
        })
        .catch((e) => reject(e))

    })

    logger.groupEnd('bootstrap.front.core');
  })
}

<template>
  <footer
    v-if="enabled" 
    class="n-footer">
    <div class="n-footer__container">

      <!-- Content -->
      <section class="n-footer__content">
        <!-- Powered -->
        <div class="n-footer__powered">
          <div class="group">
            <div class="n-footer__label">{{ $t('resto.footer_poweredby') }}</div>
            <a 
              :href="$t('resto.footer_resto_link', { lang: lang })" 
              class="n-footer__cta" 
              target="_blank">
              <ui-img 
                class="n-footer__logo" 
                :src="logo" 
                alt="Sayl Resto Logo"
              />
            </a>
          </div>
          <div class="group">
            <div 
              class="n-footer__statement"
              v-html="$t('resto.footer_sayl_description')"></div>
          </div>
        </div>

        <!-- Visit us -->
        <div class="n-footer__visit">
          <div class="n-footer__label">{{ $t('resto.footer_visitus') }}</div>
          <ul class="n-footer__socials">
            <li 
              v-for="(s, i) in socials"
              :key="i"
              class="n-footer__social">
              <a 
                :href="s.href" 
                class="n-footer__sociallink"
                target="_blank"
                :title="s.label">
                <ui-img 
                  class="n-footer__socialfigure"
                  :src="s.src" 
                  :alt="s.label"
                />
              </a>
            </li>
          </ul>
        </div>
      </section>

      <!-- License -->
      <section class="n-footer__license">
        
        <!-- Copyright -->
        <div class="n-footer__copyright" v-html="$t('resto.copyright', { year, lang: lang })"></div>

        <!-- Legal -->
        <ul class="n-footer__legal">
          <li
            v-for="item in legal"
            :key="item.label" 
            class="n-footer__item">
            <a 
              :href="item.href"
              class="n-footer__link"
              target="_blank"
              rel="nofollow"
              v-if="item.href"
            >{{ item.label }}</a>

            <div 
              class="n-footer__link -action"
              rel="nofollow"
              @click="item.event"
              v-if="item.event"
            >{{ item.label }}</div>
          </li>
        </ul>
      </section>
    </div>
  </footer>
</template>

<script>
import Config from '@/config'
import UiImg from '@/components/ui/img'

export default {
  name: 'SaylFrontFooter',

  components: { UiImg },

  inject: [
    '$user', 
    '$embed'
  ],

  data(){
    return {
      email: null,
    }
  },

  computed: {
    enabled() {
      let meta = this.$route.meta;
      return !meta.hasOwnProperty('footer') || (meta.hasOwnProperty('footer') && meta.footer === true)
    },

    legal() {
      return [
        // { label: this.$t('resto.burger_legal_cookies_settings'), event: this.onCookiesSettings },
        { label: this.$t('resto.burger_legal_term_and_conditions'), href: this.$t('resto.burger_legal_term_and_conditions_link', { lang: this.lang }) },
        { label: this.$t('resto.burger_legal_privacy_policy'), href: this.$t('resto.burger_legal_privacy_policy_link', { lang: this.lang }) },
      ]
    },

    lang() {
      return !this.$basil.isNil(this.user.lang) ? this.user.lang : 'en'
    },

    logo() {
      return Config.cdn + 'statics/images/logos/sayl/color.svg'
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    socials() {
      return [
        { label: this.$t('resto.footer_resto_label'), href: this.$t('resto.footer_resto_link', { lang: this.lang }), src: `${Config.cdn}statics/images/logos/resto/logomark-grey.svg` },
        { label: 'Instagram', href: 'https://www.instagram.com/saylresto/', src: `${Config.cdn}statics/images/icons/social/instagram-bn.svg` },
        { label: 'Facebook', href: 'https://www.facebook.com/saylcloud', src: `${Config.cdn}statics/images/icons/social/facebook-bn.svg` },
      ]
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    year(){
      return new Date().getFullYear()
    }
  },

  methods: {
    reset() {
      try {
        let e = this.$el
        let b = !!e ? e.getBoundingClientRect() : null
        let h = (!!b ? b.height : 0 | 0) + (1 * 16)
  
        document.getElementsByTagName('main')[0].style.marginBottom = `${h}px`
      }
      catch (error) {}
    },

    onCookiesSettings() {
      this.$bus.$emit('cookies', { force: true })
    },

    onResize() {
      this.reset()
    }
  },

  mounted() {
    window.addEventListener('resize', this.$basil.debounce(this.onResize.bind(this), 500))
    this.reset()
  },

  destroy() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<template>
  <div class="app-loader__error">
    <ui-error 
      class="ui-error-en"
      subtitle="The store your request does not exists."
      title="Store not found"
      />
      
    <div class="group">
      <ui-error 
        appearance="secondary"
        class="ui-error-nl"
        :header="false"
        subtitle="De door u aangevraagde winkel bestaat niet."
        title="Winkel niet gevonden"
        />
      <ui-error 
        appearance="secondary"
        class="ui-error-fr"
        :header="false"
        subtitle="Le magasin que vous avez demandé n'existe pas."
        title="Magasin introuvable"
        />
    </div>
  </div>
</template>

<script>
import UiError from '@/components/ui/error'

export default {
  name: 'BootstrapErrorView',
  
  components: {
    UiError
  }
}
</script>

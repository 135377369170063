import NotFound from './views/errors/notfound'

const routes = [
  {
    path: '*',
    component: NotFound
  }
]

export default routes

import { default as Config, isStaging, isProduction } from './config'

if (isStaging()){
  __webpack_public_path__ = 'https://cdn-apps.sayl.cloud/staging/askemma/'
}
if (isProduction()){
  __webpack_public_path__ = 'https://cdn-apps.sayl.cloud/askemma/'
}

import Vue from 'vue'
import VueBootstrap from '@/bootstrap/'
import VueScrollReveal from 'vue-scroll-reveal'
import '@/styles/app.scss'

import { applyPolyfills, defineCustomElements } from '@conn3ct/auth-button/loader'

Vue.config.productionTip = false;

Vue.config.ignoredElements = [/sayl-\w*/];

applyPolyfills().then(() => {
  defineCustomElements();
});

Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
  duration: 800,
  scale: .98,
  distance: '10px',
});

Vue.use( VueBootstrap );

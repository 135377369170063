import Vue from 'vue'
import Config from '@/config'
import LocalModules from '@/modules'

let l = null;

function loadModule({ fqn, url, curry, ...options }) {
  const ginger = Vue.prototype.$spices.ginger;
  options.curry = curry;
  
  if (url.length != 0){
    url = [Config.transports.module, url].join('');
  }
  
  l.info(fqn);
  
  return ginger.register({
    fqn: fqn,
    url: url,
    options: options
  });
}

function getLocalModules({ curry }) {
  l.info(`bootstrap.modules.local ${LocalModules.length}x`);
  return Promise.all(LocalModules.map(({ fqn, url, ...options }) => loadModule({ fqn, url, curry, options })));
}

function getDistantModules({ modules, curry }) {
  l.info(`bootstrap.modules.distants ${modules.length}x`);

  return Promise.all(modules.map(({ fqn, url, ...options }) => loadModule({ fqn, url, curry, options })));
}

export default ({ logger, router, states, store }) => {
  l = logger;
  return new Promise((resolve, reject) => {
    logger.group('bootstrap.modules');

    store.dispatch('bootstrap/status', states.APPLICATION_MODULES);
    store.dispatch('bootstrap/getConfig').then(config => {
      getDistantModules({ modules: config.modules, curry: config.curry })
        .then(() => getLocalModules({ curry: config.curry }))
        .then(() => {
          const ginger = Vue.prototype.$spices.ginger;
          let data = ginger.run({ action: 'navigation' });
          store.dispatch(`bootstrap/navigation`, data);

          logger.groupEnd('bootstrap.modules');
          resolve();
        })
        .catch(e => reject(e))
    })
  })
}

<template>
  <main>
    <div class="container">
      <h1 class="view-cms__title">{{ $t(title)}}</h1>
      <div class="view-cms__content" v-html="$t(content)"></div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'CMSGenericView',

  computed:{
    content(){
      return `${this.key}_content`;
    },

    key(){
      return `sayl-oat.${this.$route.meta.key}`;
    },

    title(){
      return `${this.key}_title`;
    }
  }
}
</script>

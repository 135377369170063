<template>
  <div :class="classes">
    <!-- Header -->
    <header 
      v-if="header"
      class="ui-error__header">
      <ui-img 
        :src="logo" 
        alt="Sayl Resto Logo"
      />
    </header>

    <!-- Body -->
    <div class="ui-error__body">
      <h1 class="ui-error__title">{{ title }}</h1>
      <h2 v-if="subtitle" class="ui-error__subtitle">{{ subtitle }}</h2>
    </div>

    <!-- Footer -->
    <footer class="ui-error__footer">
      <actions-button 
        v-if="actionLabel"
        appearance="primary" 
        :size="actionSize"
        @click="$emit('action')">{{ actionLabel }}</actions-button>
      <p class="ui-error__description" v-html="description"></p>
    </footer>
  </div>
</template>


<script>
import Config from '@/config'
import UiImg from '@/components/ui/img'

export default {
  name: 'UiError',

  components: {
    UiImg,
  },

  props: {
    actionLabel: {
      type: String
    },

    actionSize: {
      type: String,
      default: 'm'
    },

    appearance: {
      type: String,
      default: 'primary'
    },

    description: {
      type: String
    },

    header: {
      type: Boolean,
      default: true
    },

    subtitle: {
      type: String
    },

    title: {
      type: String,
      required: true
    },
  },

  computed: {
    classes(){
      return {
        'ui-error': true,
        '-primary': this.appearance === 'primary',
        '-secondary': this.appearance === 'secondary'
      }
    },

    logo(){
      return Config.cdn + 'statics/images/logos/resto/color.svg'
    },
  }
}
</script>

import Routes from './views'

class CMS {
  constructor() {
    this.fqn = 'front-cms'
  }

  get routes() {
    let ret = Routes;
    ret.forEach((route) => {
      if (!route.meta) {
        route.meta = {}
      }

      route.meta.module = this.fqn;
    });

    return ret;
  }

  get stores() {
    return [];
  }

  install({ fqn }) {
    this.fqn = fqn;
  }
}

export default new CMS()

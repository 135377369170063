import { basil } from '@spices/basil' 

/**
 * ISO639 is about the languages codes.
 * Expressed usually in two or three characters.
 * @see https://www.loc.gov/standards/iso639-2/php/English_list.php
 */
const ISO639_2 = 'ISO639-2'; // en
const ISO639_3 = 'ISO639-3'; // eng

/**
 * ISO3166 is about the country codes.
 * Expressed usually in two or three characters.
 * @see https://www.iso.org/obp/ui#iso:pub:PUB500001:en
 */
const ISO3166_2 = 'ISO3166-2'; // us
const ISO3166_3 = 'ISO3166-3'; // usa

/**
 * The combinations of the two sets is described in 
 * the RFC 5646 from the IETF
 * @see https://tools.ietf.org/html/rfc5646
 */

/**
 * Locale
 * 
 * @class
 */
export default class Locale {

  /**
   * @constructor
   * @param {String} value 
   */
  constructor( value ) {
    if (basil.isNil(value)){
      throw new Error(`Not a valid value for Locale ${value}`);
    }
    
    if (value instanceof Locale){
      value = value.toString()
    }

    this.value = value
    let parts = value.split(/[-_]/);
    this._lang = new Lang(parts[0]);
    this._country = new Country(parts[1]);
  }

  /**
   * The country associated with the current locale
   * 
   * @readonly
   * @property {String}
   */
  get country(){
    return this._country.alpha2;
  }
  
  /**
   * The lang associated with the current locale
   * 
   * @readonly
   * @property {String}
   */
  get lang(){
    return this._lang.alpha2;
  }

  /**
   * The langtag version of the locale
   * 
   * @property {String}
   * @readonly
   */
  get langtag(){
    let ret = []
    if (this._lang && this._lang.valid){ ret.push(this._lang) }
    if (this._country && this._country.valid){ ret.push(this._country) }

    return ret.join('-');
  }

  /**
   * The iso version of the locale
   * @property {String}
   * @readonly
   */
  get iso() {
    return this.valid ? [this.lang, this.country.toUpperCase()].join('_') : null;
  }

  /**
   * @property {Boolean}
   * @readonly
   */
  get valid(){
    return (this._lang && this._lang.valid) && 
           (this._country && this._country.valid)
  }

  /**
   * @returns {String}
   */
  toString(){
    return this.langtag
  }
}

class Lang{
  /**
   * @constructor
   * @param {String} value The language code
   */
  constructor(value){
    this._value = value;
  }

  /**
   * Return the alpha 2 version
   * 
   * @property {String}
   * @readonly
   */
  get alpha2(){
    return this._value || null;
  }

  /**
   * @property {Boolean}
   * @readonly
   */
  get valid(){
    return this._value && this._value.length > 0;
  }

  /**
   * The String representation
   * 
   * @property {String}
   * @readonly
   */
  toString(){
    return this.alpha2;
  }
}

class Country{

  /**
   * @constructor 
   * @param {String} value 
   */
  constructor(value){
    this._value = value;
  }

  /**
   * Return the alpha 2 version
   * 
   * @property {String}
   * @readonly
   */
  get alpha2() {
    return this._value || null;
  }

  /**
   * @property {Boolean}
   * @readonly
   */
  get valid() {
    return this._value != null && this._value.length > 0;
  }

  /**
   * The String representation
   * 
   * @property {String}
   * @readonly
   */
  toString() {
    return this.alpha2;
  }
}

import Config from '@/config'

export default () => {

  console.log(
    `%c @sayl/front %c v${Config.version.client} %c`,
    'background:#4E575D ; padding: 1px; border-radius: 3px 0 0 3px;  color: #FBF3EC',
    'background:#2C9CCB ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
    'background:transparent'
  )

  console.log(
    `%c @sayl/front-core %c v${Config.version.core} %c`,
    'background:#4E575D ; padding: 1px; border-radius: 3px 0 0 3px;  color: #FBF3EC',
    'background:#2C9CCB ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
    'background:transparent'
  )

  console.log(
    `%c @conn3ct/auth-button %c v${Config.version.conn3ctAuthButton} %c`,
    'background:#4E575D ; padding: 1px; border-radius: 3px 0 0 3px;  color: #FBF3EC',
    'background:#2C9CCB ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
    'background:transparent'
  )

  return Promise.resolve()
}

let modules = [
    {
      fqn: 'front-admin',
      url: () => require('./admin')
    },
    {
      fqn: 'front-checkout',
      url: () => require('./checkout')
    },
    {
      fqn: 'front-cms',
      url: () => require('./cms')
    },
    {
      fqn: 'front-user',
      url: () => require('./user')
    },
    {
      fqn: 'front-catalog',
      url: () => require('./catalog')
    },
];

export default modules

import eventbus from "../eventbus"

export default () => {
  // Duration of a period for idle timoout (ms)
  let periodDuration = parseInt(process.env.VUE_APP_IDLE_TIMEOUT) || 60000;

  // Number of periods before the user is warned
  let warningPeriods = parseInt(process.env.VUE_APP_IDLE_WARNING_PERIODS) || 9;

  // Number of periods after the warning before the user is reset
  let resetPeriods = parseInt(process.env.VUE_APP_IDLE_RESET_PERIODS) || 1;

  let periods = 0;

  let timeoutId = setTimeout(timerIncrement, periodDuration);

  let reset = () => {
    clearTimeout(timeoutId);
    periods = 0;
    timeoutId = setTimeout(timerIncrement, periodDuration);
  }

  document.body.addEventListener('mousemove', reset);
  document.body.addEventListener('keypress', reset);
  document.body.addEventListener('click', reset);

  function timerIncrement() {
    periods++;

    if(periods === warningPeriods) {
      eventbus.$emit('idle-timeout');
    }

    if(periods === warningPeriods + resetPeriods) {
      eventbus.$emit('reset-user');
    }

    timeoutId = setTimeout(timerIncrement, periodDuration);
  }
}

import {
  mapGetters,
  mapState
} from 'vuex'

export default {
  inject: [
    '$core',
    '$embed',
  ],

  computed: {
    ...mapState({
      i18n: state => state.bootstrap.i18n,
      extensions: state => state.bootstrap.data.extensions,
      isMultiShop: state => state.bootstrap.isMultiShop,
      state: state => state['sayl-front.catalog.multishop'],
    }),

    ...mapGetters({
      getOat: 'bootstrap/oat',
    }),

    subshopKey() {
      return this.$basil.get(this.state, 'subshopKey')
    },

    // States
    catalog() {
      return this.$basil.get(this.state, 'catalog')
    },

    catalogs() {
      return this.$basil.get(this.state, 'catalogs')
    },

    shopLoading() {
      return this.$basil.get(this.state, 'loading', false)
    },

    shopsLoading() {
      return this.$basil.get(this.state, 'shopsLoading', false)
    },

    subshop() {
      return this.$basil.get(this.state, 'shop')
    },

    subshops() {
      return this.$basil.get(this.state, 'shops')
    },

    baseShop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    shop() {
      return this.subshop && this.isMultiShop ? this.subshop : this.$basil.get(this.$embed, 'shop.model')
    },

    filteredSubshops() {
      return (this.subshops || []).filter(s => {
        let service = s.services?.find(s => s.name === this.service)
        return service?.active ?? false
      })
    },

    lang() {
      return this.i18n.locale.lang.toString()
    },

    oat() {
      if(!this.isMultiShop) {
        return this.getOat
      }
      return this.subshopKey && this.getOatFunc(this.shop.id)
    },

    service() {
      return this.$route.params.service
    },
  },

  methods: {
    getOatFunc(shop) {
      let ret = this.$basil.get(this.extensions, 'oat', [])
      let f = ret.find(s => s.shop_id === shop)
      if(!f) {
        f = this.getOat
      }

      // console.error('REMOVE THOSE ELEMENTS !!')
      // f.category_navigation = 'aside'

      return f
    }
  },
}

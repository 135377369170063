<template>
  <div :class="classes">

    <!-- Icon -->
    <ui-icon
      class="cart-badge__icon"
      glyph="bag"
      />

    <!-- Qty -->
    <data-badge
      class="cart-badge__badge"
      v-if="nCartItem > 0 && !amount"
      :value="nCartItem"
      />

    <div
      v-if="amount"
      class="cart-badge__total">{{ total }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'CheckoutCartBadge',

  props: {
    amount: {
      type: Boolean,
      default: false
    }
  },

  mixins: [
    MixinCurrency
  ],

  computed: {
    ...mapState({
      cart: state => state['sayl-front.cart'].cart,
    }),

    classes() {
      return {
        'cart-badge': true,
        '-is-active': this.nCartItem > 0
      }
    },

    nCartItem() {
      let items = this.$basil.get(this.cart, 'items', [])
      let ret = 0

      if(!this.$basil.isNil(items)) {
        items.forEach(i => {
          ret += i.quantity
        })
      }
      return ret || 0
    },

    total() {
      let ret = this.$basil.get(this.cart, 'total.value', 0)
      return this.toCurrency(ret)
    }
  }
}
</script>

import Vue from 'vue'
import { VueGinger } from '@spices/ginger'

// import modules from '@/modules'

export default ({ eventbus, logger, router, states, store, transports }) => {
  return new Promise((resolve) => {
    logger.group('bootstrap.ginger');

    store.dispatch('bootstrap/status', states.APPLICATION_INIT);

    Vue.use(VueGinger, {
      // config: modules,
      config: [],
      eventbus,
      logger,
      transports,
      router,
      store
    });

    logger.groupEnd('bootstrap.ginger');
    resolve();
  })
}

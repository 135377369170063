import Routes from './views'
import Store from './store'

class Checkout {
  constructor() {
    this.fqn = 'front-checkout'
  }

  get routes() {
    let ret = Routes();
    ret.forEach((route) => {
      if (!route.meta) {
        route.meta = {}
      }

      route.meta.module = this.fqn;
    });

    return ret;
  }

  get stores() {
    return [Store];
  }

  install({ fqn }) {
    this.fqn = fqn;
  }
}

export default new Checkout()

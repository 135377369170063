export default () => {
  let ret = [
    {
      name: 'sayl-front-user.home',
      path: '/user/',
      component: () => import('./home'),
      meta: {
        bodyClass: 'view-user-home layout-module-users',
        scrollTop: true,
        header: true,
        footer: true
      }
    },

    {
      name: 'sayl-front-user.loyalty',
      path: '/user/loyalty',
      component: () => import('./loyalty/index.vue'),
      meta: {
        bodyClass: 'view-user-loyalty layout-module-users',
        scrollTop: true,
        header: true,
        footer: true
      }
    },

    {
      name: 'sayl-front.challenges',
      path: '/challenges',
      component: () => import('./challenges/index.vue'),
      meta: {
        bodyClass: 'view-challenges layout-module-users',
        scrollTop: true,
        header: true,
        footer: true
      }
    },

    {
      name: 'sayl-front.challenge',
      path: '/challenges/:id',
      component: () => import('./challenges/item.vue'),
      meta: {
        bodyClass: 'view-challenge layout-module-users',
        scrollTop: true,
        header: true,
        footer: true
      }
    },

    {
      name: 'sayl-front-user.challenges',
      path: '/user/challenges',
      component: () => import('./challenges/index.vue'),
      meta: {
        bodyClass: 'view-challenges layout-module-users',
        scrollTop: true,
        header: true,
        footer: true
      }
    },

    {
      name: 'sayl-front-user.challenge',
      path: '/user/challenge/:id',
      component: () => import('./challenges/item.vue'),
      meta: {
        bodyClass: 'view-challenge layout-module-users',
        scrollTop: true,
        header: true,
        footer: true
      }
    },

    //
    // Account
    //
    {
      name: 'sayl-front-user.addresses',
      path: '/user/addresses',
      component: () => import('./account/addresses'),
      meta: {
        bodyClass: 'view-user-addresses layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },

    {
      name: 'sayl-front-user.balance',
      path: '/user/balance',
      component: () => import('./loyalty/index.vue'),
      meta: {
        bodyClass: 'view-user-loyalty layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },
    {
      name: 'sayl-front-user.balance.vouchers',
      path: '/user/balance',
      component: () => import('./loyalty/index.vue'),
      meta: {
        bodyClass: 'view-user-loyalty layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
        action: 'vouchers'
      }
    },

    {
      name: 'sayl-front-user.balance.balance',
      path: '/user/balance',
      component: () => import('./loyalty/index.vue'),
      meta: {
        bodyClass: 'view-user-loyalty layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
        action: 'balance'
      }
    },
    {
      name: 'sayl-front-user.balance.cards',
      path: '/user/balance',
      component: () => import('./loyalty/index.vue'),
      meta: {
        bodyClass: 'view-user-loyalty layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
        action: 'cards'
      }
    },

    {
      name: 'sayl-front-user.orders',
      path: '/user/orders',
      component: () => import('./account/orders'),
      meta: {
        bodyClass: 'view-user-orders layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      },
      children: [
        {
          name: 'sayl-front-user.order',
          path: '/user/order/:id',
          component: () => import('./account/order'),
          meta: {
            bodyClass: 'view-user-order layout-module-users',
            scrollTop: true,
            header: true,
            footer: true,
          }
        },
      ]
    },
    {
      name: 'sayl-front-user.user',
      path: '/user/profile',
      component: () => import('./account/profile'),
      meta: {
        bodyClass: 'view-user-profile layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },

    {
      name: 'sayl-front-user.company',
      path: '/user/company',
      component: () => import('./account/company.vue'),
      meta: {
        bodyClass: 'view-user-company layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },

    {
      name: 'sayl-front-user.vouchers',
      path: '/user/vouchers',
      component: () => import('./account/vouchers.vue'),
      meta: {
        bodyClass: 'view-user-vouchers layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },

    //
    //  Authentification
    //

    {
      name: 'sayl-front-user.login',
      path: '/login',
      component: () => import('./auth/login'),
      meta: {
        bodyClass: 'view-user-login layout-module-users layout-user-auth',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },
    {
      name: 'sayl-front-user.signup',
      path: '/signup',
      component: () => import('./auth/signup'),
      meta: {
        bodyClass: 'view-user-signup layout-module-users layout-user-auth',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },
  ]

  return ret
}

<template>
  <div class="app-loader__error">
    <ui-error 
      class="ui-error-en"
      subtitle="The shop you request is not published"
      title="Shop not published"
      />
      
    <div class="group">
      <ui-error 
        appearance="secondary"
        class="ui-error-nl"
        :header="false"
        subtitle="De winkel die je opvraagt ​​is niet gepubliceerd"
        title="Winkel niet gepubliceerd"
        />
      <ui-error 
        appearance="secondary"
        class="ui-error-fr"
        :header="false"
        subtitle="La boutique que vous demandez n'est pas publiée"
        title="Boutique non publiée"
        />
    </div>
  </div>
</template>

<script>
import UiError from '@/components/ui/error'

export default {
  name: 'BootstrapErrorView',
  
  components: {
    UiError
  }
}
</script>

<template>
  <header
    v-bind="attrs"
    v-if="enabled">
    <div
      class="n-header__container"
      v-click-outside="outside">
      <!-- first -->
      <div class="n-header__group n-header__first">
        <router-link :to="logoHref">
          <ui-img
            alt=""
            class="n-header__logo"
            :src="logo"
          />
        </router-link>
      </div>

      <!-- second -->
      <div
        v-if="!disableNavigation"
        class="n-header__group n-header__second">
        <!-- User -->
        <router-link
          class="n-header__button n-header__item-user user"
          :to="userLink"
          v-if="!isCatalogReadonly && !isModeTt">
          <ui-icon
            class="user__icon"
            glyph="user"
          />

          <span class="user__label">{{ userLabel }}</span>
        </router-link>

        <!-- Locale -->
        <button
          class="n-header__button n-header__item-locale locale"
          ref="locale"
          @click.stop.prevent="onLocales">
          <ui-icon
            class="locale__icon"
            glyph="globe"
          />

          <span class="locale__label">{{ userLang }}</span>
        </button>

        <!-- Cart -->
        <router-link
          class="n-header__button n-header__bag"
          :to="{ name: 'sayl-front-checkout.cart' }"
          v-if="!isCatalogReadonly">
          <component
            amount
            :is="cardBadge"
          />
        </router-link>
      </div>

      <!-- Third -->
      <div
        v-if="!disableNavigation"
        class="n-header__group n-header__third">
        <!-- Burger -->
        <div class="n-header__item">
          <button
            ref="burgercta"
            class="n-header__button n-header__burger-cta"
            @click="showBurger">
            <span class="n-header__button-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>
      </div>
    </div>

    <modal-locales
      :target="$refs.locale"
      :visible="showLocales"
      @close="onLocalesClose"
      @update="onChangeLang"
    />
  </header>
</template>

<script>
import {
  mapGetters,
  mapState
} from 'vuex'

import { ClickOutside } from '@spices/pepper'
import CheckoutCartBadge from '@/modules/checkout/components/cart/badge'
import CatalogCartBadge from '@/modules/catalog/components/cart/badge'
import ModalLocales from '../modal/locales.vue'
import UiImg from '@/components/ui/img'

import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'NavigationsHeader',

  components: {
    ModalLocales,
    UiImg,
  },

  directives: {
    ClickOutside
  },

  inject: [
    '$core',
    '$embed',
    '$user',
    '$localStorage'
  ],

  mixins: [ MixinMultishop, ],

  data() {
    return {
      outside: {
        handler: this.onClickOutside,
        middleware: this.clickOutsideMiddleware
      },
      key: 1,
      showLocales: false,
      canShowLocales: true,
    }
  },

  computed:{
    ...mapState({
      burger: state => state.bootstrap.burger,
      cart: state => state['sayl-front.cart'].cart,
      i18n: state => state.bootstrap.i18n,
      ser: state => state['sayl-front.service'].service,
      tables: state => state['sayl-front.service'].table,
    }),

    ...mapGetters({
      oat: 'bootstrap/oat',
    }),

    attrs() {
      return {
        class: this.classes,
      }
    },

    cardBadge() {
      let ret = this.$route.name === 'sayl-front-checkout.cart'
      return this.isCheckout && !ret ? CheckoutCartBadge : CatalogCartBadge
    },

    classes() {
      return {
        'n-header': true,
        '-is-active': !this.burger
      }
    },

    disableNavigation() {
      return this.$route.name === 'sayl-front-catalog.b2b'
    },

    embed() {
      return this.$localStorage.embed || this.$route.params.embed || this.$basil.get(this.$embed, 'embed.model.id')
    },

    enabled() {
      let meta = this.$route.meta;
      return !meta.hasOwnProperty('header') || (meta.hasOwnProperty('header') && meta.header === true)
    },

    isCatalogReadonly() {
      return this.$basil.get(this.oat, 'is_catalog_readonly', false)
    },

    isCheckout() {
      return this.$basil.get(this.$route, 'meta.module', '') === 'front-checkout'
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin'
    },

    logo() {
      return this.$basil.get(this.baseShop, 'logo', this.$basil.get(this.shop, 'logo'))
    },

    logoHref() {
      if(this.disableNavigation) {
        return {
          name: 'sayl-front-catalog.b2b',
          params: {
            embed: this.$route.params.embed
          }
        }
      }

      let ret = { name: 'sayl-front-catalog.services' }

      if(this.embed) {
        ret = { name: 'sayl-front-catalog.services', params: { embed: this.embed }}

        if(this.ser && this.ser.name) {
          ret = {
            name: this.isMultiShop ? 'sayl-front-catalog.shops' : 'sayl-front-catalog.services',
            params: {
              embed: this.embed,
              service: this.isMultiShop ? this.ser.name : null,
            }
          }
        }
      }

      return ret
    },

    nCartItem() {
      return this.cart ? this.cart.items.length : 0
    },

    tableNumber() {
      let ret = ''
      const t = this.tables.find(t => t.id === this.cart.tableNumber)

      if(t) {
        ret = t.name || ''
      }

      return ret
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userLabel() {
      return this.user.anonymous === true ?
             this.$t('resto.burder_actions_login'):
             `${this.user.firstname} ${this.user.lastname}`
    },

    userLink() {
      return { name: 'sayl-front-user.home' }
    },

    userLang() {
      return this.key && this.$basil.get(this.user, 'lang', this.i18n.locale.lang.toString())
    },
  },

  methods:{
    showBurger() {
      this.$store.dispatch('bootstrap/burger', true)
    },

    clickOutsideMiddleware(event){
      return true
    },

    onLocalesClose() {
      if(this.showLocales === true) {
        this.showLocales = false
        this.canShowLocales = false
        setTimeout(() => this.canShowLocales = true, 200)
      }
    },

    onLocales() {
      this.showLocales = this.canShowLocales ? !this.showLocales : this.showLocales

      if(this.showLocales === true) {
        this.canShowLocales = false
      }

      setTimeout(() => this.canShowLocales = true, 200)
    },

    onChangeLang(value) {
      this.i18n.setLocale(value)
      this.showLocales = false
      this.canShowLocales = false
      setTimeout(() => this.canShowLocales = true, 200)

      this.$core.changeLang({ locale: value.iso })
        .then(() => this.$bus.$emit('change-lang'))
        .catch((e) => $console.error(e))
        .finally(() => this.key++)
    },

    onClickOutside(event) {
      this.showFavorites = false
      this.showRecent = false
      this.showUser = false
      // this.showLocales = false
    },
  }
}
</script>

<template>
  <img
    :alt="alt"
    :class="$attrs.class"
    :src="src"
    v-if="result"
  />
</template>

<script>
export default {
  name: 'UiImg',

  props: {
    alt: {},
    dataSrc: {},
    src: { type: String },
  },

  data() {
    return {
      result: false
    }
  },

  computed: {

  },

  methods: {
    reset() {
      let tester = new Image()
      tester.onload = () => { this.result = true }
      tester.onerror = () => { this.result = false }
      tester.src = this.src
    }
  },

  mounted() {
    this.reset()
  }
}
</script>

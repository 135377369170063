<template></template>

<script>
import { 
  mapGetters,
  mapState,
} from 'vuex'

export default {
  name: 'ModalCookies',

  inject: ['$embed'],

  computed: {
    ...mapState({
      bootstrap: state => state.bootstrap.data
    }),

    ...mapGetters({
      oat: 'bootstrap/oat',
    }),

    hideCookiesModal() {
      return this.$basil.get(this.bootstrap, 'gdpr.disable_cookies_consent') || this.$basil.get(this.oat, 'disable_cookies_consent', false) === true
    },
  },
  
  methods: {
    show(force = false) {
      let actives = {
        analytics: [
          !this.$basil.isNil(this.$basil.get(this.$embed, 'embed.model.analytics.gtagId', null)),
          !this.$basil.isNil(this.$basil.get(this.$embed, 'embed.model.analytics.gaId'), null),
          !this.$basil.isNil(this.$basil.get(this.$embed, 'embed.model.analytics.fbPixelId'), null),
        ].filter(a => a === true).length > 0
      }

      let options = [
        {
          title: this.$t('resto.cookies_options_technical_title'),
          description: this.$t('resto.cookies_options_technical_description'),
          required: true,
          key: 'technical'
        },
        actives.analytics ? {
          title: this.$t('resto.cookies_options_analytics_title'),
          description: this.$t('resto.cookies_options_analytics_description'),
          key: 'analytics'
        } : null,
      ].filter(opt => opt != null)

      if(this.hideCookiesModal) {
        this.$cookies.cook(options.map(opt => opt.key))
        return
      }
      
      this.$cookies({
        blanket: true,
        description: this.$t('resto.cookies_default_description'),
        expired: parseInt(this.$basil.get(this.bootstrap, 'gdpr.cookies_expiration', 60 * 60 * 24 * 30), 10) * 1000,
        force: force,
        i18n: {
          personalize: this.$t('resto.cookies_personalize_action'),
          confirm: this.$t('resto.cookies_confirm_action'),
          accept: this.$t('resto.cookies_accept_all_action'),
          back: this.$t('resto.cookies_back_all_action'),
        },

        name: `cookies_${this.$basil.get(this.$embed, 'embed.model.id')}`,
        title: this.$t('resto.cookies_default_title'),
        options,
        callback: force === true ? () => window.location.reload() : null,
      })
    }
  },

  mounted() {
    this.$bus.$on('cookies', (opt) => {
      if(opt.force == true) {
        this.show(true)
        return
      }
      this.show()
    })

    this.show()
  },

  beforeDestroy() {
    this.$bus.$off('cookies')
  }
}
</script>
/**
 * Application status
 */
import states from '@/bootstrap/states'
import { basil } from '@spices/basil'

export default {
  name: 'bootstrap',
  namespaced: true,

  state: {
    fqn: null,
    model: null,

    // Loading
    errors: [],
    status: 0,
    states: states,

    // Navigations
    navigation: null,
    burger: true,

    // i18n
    i18n: null,

    // Config (cp bootstrap)
    data: null,
    args: null,

    // confirm modal
    confirm: false,

    // is multi shop
    isMultiShop: false,
    masterShopId: null,
  },

  getters: {
    oat: (state, getters, rootState) => {
      let d = {
        allergens: [],
        category_navigation: 'top',
        custom_tc_url: null,
        disable_cookies_consent: true,
        has_comment: { all: true },
        has_email: { all: true },
        has_fl_selection: true,
        has_newsletter: { all: true },
        has_phone: { all: true },
        has_search : false,
        is_pickup_asap_enabled: { all: false },
        is_pickup_asap_only: { all: false },
        is_catalog_readonly: false,
        is_category_pictures_enabled: true,
        is_multi_shops_enabled: false,
        is_challenge_enabled: false,
        is_email_required: { all: true },
        is_newsletter_opt_out: { all: true },
        is_phone_required: { all: true },
        is_product_pictures_enabled: true,
        is_recommendations_enabled: false,
        is_terms_opt_out: { all: true },
        // is_tracing_form_enabled: false,
        is_layout_product_inverted: false,
        is_voucher_enabled: true,
        pin : '1234',
        template : 'spa',
      };

      let shopId = basil.get(rootState['sayl-front.catalog.multishop'], 'subshop.id', null)
      let masterShopId = basil.get(state, 'data.shop.id', null)

      let shop = shopId ? shopId : masterShopId
      let settings = basil.get(state, 'data.extensions.oat', [])
      let ret = { allergens: [] }

      if(state.masterShopId == null) {
        state.masterShopId = masterShopId
      }

      if(settings && shop != null) {
        ret = settings.find(s => s.shop_id === shop)

        if(!basil.isNil(ret)) {
          let allergens = basil.get(ret, 'allergens', [])
          ret.allergens = allergens.sort((a, b) => a.position - b.position)

          if(shop === state.masterShopId) {
            state.isMultiShop = ret.is_multi_shops_enabled
          }
        }
      }

      return Object.assign(d, ret)
    },

    isTableTop: (state) => {
      return basil.get(state.args, 'mode') === 'tt'
    }
  },

  actions: {
    burger({ commit }, value) {
      commit('setBurger', value);
    },

    error({ commit }, value){
      commit('addError', value);
    },

    getConfig({ state }){
      return Promise.resolve( state.data );
    },

    locale({ state }, value){
      state.i18n.setLocale(value.locale)
    },

    navigation({ commit }, value){
      commit('setNavigation', value);
    },

    status({ commit }, status){
      commit('setStatus', status);
    },
  },

  mutations: {
    addError(state, value){
      state.errors.push(value);
    },

    setArgs(state, value){
      state.args = value;
    },

    setBurger(state, value){
      state.burger = value === true;
    },

    setConfig(state, value){
      state.data = value;
    },

    setI18n(state, value){
      state.i18n = value;
      state.locale = value.locale;
      state.locales = value.locales;
    },

    setNavigation(state, value){
      state.navigation = value;
    },

    setStatus(state, value){
      state.status = value;
    },

    setConfirm(state, value) {
      state.confirm = value;
    },
  }
}

import DataLayer from '../data'

export default ({ logger, store, eventbus }) => {
  return new Promise((res) => {
    logger.group('bootstrap.reset');

    const dl = new DataLayer();

    eventbus.$on('sayl-front.reset', ({ resolve, reject }) => {
      dl.reset({ store })
        .then(resolve)
        .catch(e => reject(e))
    });

    logger.groupEnd('bootstrap.reset');
    res();
  })
}

export default ({ eventbus, logger }) => {
  logger.group('bootstrap.observer');

  let observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        eventbus.$emit('ui.observe.visible', entry);
      });
    },
    { rootMargin: "0px 0px 0px 0px" });

  eventbus.$on('ui.observe.start', (target) => {
    observer.observe(target);
  })

  eventbus.$on('ui.observe.stop', (target) => {
    observer.unobserve(target);
  })

  logger.groupEnd('bootstrap.observer');

  return Promise.resolve();
}

<template>
  <div :class="classes">
    <!-- Icon -->
    <ui-icon
      class="cart-badge__icon"
      glyph="bag"
    />

    <!-- Qty -->
    <data-badge
      class="cart-badge__badge"
      :value="nCartItem"
      v-if="nCartItem > 0 && !amount"
    />

    <div
      class="cart-badge__total"
      v-if="amount"
    >{{ total }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'CheckoutCartBadge',

  mixins: [ MixinCurrency, ],

  props: {
    amount: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      cart: state => state['sayl-front.cart'].cart,
      order: state => state['sayl-front.checkout'].order,
    }),

    classes() {
      return {
        'cart-badge': true,
        '-is-active': this.nCartItem > 0
      }
    },

    items() {
      return this.$basil.get(this.order, 'order_items', [])
    },

    nCartItem() {
      let ret = 0;
      let cartItems = this.$basil.get(this.cart, 'items', [])
      if(!this.$basil.isNil(cartItems)) {
        cartItems.forEach(i => ret += i.quantity)
      } else if(!this.isNil(this.items)) {
        this.items.forEach(i => ret += i.quantity)
      }
      return ret
    },

    total() {
      let ret = this.$basil.get(this.order, 'price_total', 0)
      return this.toCurrency(ret)
    }
  }
}
</script>

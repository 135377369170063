const routes = [
  {
    path: '/admin',
    name: 'sayl-front-admin.admin',
    component: () => import('./admin'),
    meta: {
      bodyClass: 'view-admin'
    },
  }
]


export default routes;

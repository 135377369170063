import DataLayer from '../data'
import Error from '../../helpers/error'
import { basil } from '@spices/basil'
import { default as Config, isDevelopment } from '@/config'

export default ({ args, eventbus, i18n, logger, router, states, store, transports }) => {
  logger.group('bootstrap.cp');

  let cp = transports.cp;
  store.dispatch('bootstrap/status', states.APPLICATION_AUTH);

  return new Promise((resolve, reject) => {
    let embedId = Config.embedId

    if(isDevelopment() && !embedId) {
      // Casa di mama embed
      embedId = '5eccefa6e92aa456ed14d372'
    }

    if(!embedId) {
      return reject(Error.EMBED_NOTFOUND);
    }

    if(!basil.isNil(embedId)) {
      cp.defaults.headers.common['X-Embed-ID'] = embedId;
    }

    // Support for the no-cache
    if(args.nocache === '1') {
      cp.defaults.headers.common['Cache-Control'] = 'no-cache'
    }

    eventbus.$on('logout', () => {
      logger.info('logout the user please');
      dl.logout()
    });

    const dl = new DataLayer();

    dl.bootstrapClientProject({ store, locale: i18n.locale })
      .then((config) => {
        i18n.setConfig(config.i18n)
        i18n.update()

        if (!basil.isNil(embedId)){
          embedId = basil.get(config, 'embed.id')
          cp.defaults.headers.common['X-Embed-ID'] = embedId
        }

        delete cp.defaults.headers.common['Cache-Control']

        logger.groupEnd('bootstrap.cp')
        return resolve()
      })
      .catch((error) => {
        logger.info('bootstrap.cp', error)
        logger.groupEnd('bootstrap.cp')
        reject(error)
      })
  })
}

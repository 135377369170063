import App from '@/App'
import Pepper from '@spices/pepper'
import { basil as $basil, install as Basil } from '@spices/basil'

import { install as basilI18n } from '@spices/basil-ic-i18n'
import phonenumber from '@/helpers/phonenumber'
import IdlePlugin from './idle'

export default ({ core, eventbus, i18n, logger, router, store, Vue }) => {
  logger.group('bootstrap.app');

  // Plugins
  $basil.global = true
  $basil.use(basilI18n)

  Vue.use(Pepper);
  Vue.use(Basil);
  Vue.use(IdlePlugin);

  // Accessors
  Object.defineProperty(Vue.prototype, '$bus', {
    get: () => eventbus
  })

  // Global filters
  Vue.filter('phonenumber', phonenumber);

  return new Promise((resolve) => {
    new Vue({
      router,
      store,
      i18n: i18n.i18n,
      provide(){
        return {
          '$core': core,
          '$catalog': core.catalog,
          '$cart': core.cart,
          '$challenge': core.challenge,
          '$checkout': core.checkout,
          '$embed': core.embed,
          '$localStorage': core.localStorage,
          '$loyalty': core.loyalty,
          '$referral': core.referral,
          '$user': core.user,
        }
      },
      render: h => h(App)
    }).$mount('#app');

    logger.groupEnd('bootstrap.app');
    resolve();
  })

}

<template>
  <div class="app-loader__error">
    <ui-error 
      class="ui-error-en"
      subtitle="The requested service is not active at the moment."
      title="Service not active"
      />
      
    <div class="group">
      <ui-error 
        appearance="secondary"
        class="ui-error-nl"
        :header="false"
        subtitle="De aangevraagde dienst is momenteel niet actief."
        title="Service niet actief"
        />
      <ui-error 
        appearance="secondary"
        class="ui-error-fr"
        :header="false"
        subtitle="Le service demandé n'est pas actif pour le moment."
        title="Service non actif"
        />
    </div>
  </div>
</template>

<script>
import UiError from '@/components/ui/error'

export default {
  name: 'BootstrapErrorView',
  
  components: {
    UiError
  },

  inject: ['$localStorage'],

  mounted() {
    this.$localStorage.service = null;
  }
}
</script>

<template>
  <popins-modal 
    closable
    @close="onClose"
    :visible="visible"
    v-if="visible">
    <div 
      v-html="content" 
      v-if="content!=null">
    </div>
  </popins-modal>
</template>

<script>
export default {
  name: 'ModalCustom',

  data() {
    return {
      content: null,
      visible: false
    }
  },

  methods: {
    onClose() {
      this.visible = false;
      this.content = null;
    }
  },

  mounted() {
    this.$bus.$on('popin', (data) => {
      this.visible = false;
      this.content = this.$basil.get(data, 'content', null);

      let showOnce = this.$basil.get(data, 'show_once', false);
      let duration = this.$basil.get(data, 'session_duration', null);

      setTimeout(() => {
        let storedData = JSON.parse(window.sessionStorage.getItem('popinLife'));
        let expire = this.$basil.get(storedData, 'expire', null);

        if(this.$basil.isNil(storedData) || (expire && expire < (new Date).getTime()) || !showOnce) {
          this.visible = true;
        }

        if(showOnce) {
          expire = duration ? (new Date()).getTime() + duration * 60000 : null;

          const sessionStorageData = JSON.stringify({
            showOnce: showOnce,
            expire
          });
  
          window.sessionStorage.setItem('popinLife', sessionStorageData);
        } else if(!this.$basil.isNil(storedData)) {
          window.sessionStorage.removeItem(popinLife);
        }
      }, this.$basil.get(data, 'delay', 0) || 0);
    })
  },

  beforeDestroy() {
    this.$bus.$off('popin');
  }
}
</script>

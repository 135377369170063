<template>
  <div class="app-loader__error">
    <ui-error 
      class="ui-error-en"
      subtitle="The requested service is not linked to a catalog."
      title="No catalog"
      />
      
    <div class="group">
      <ui-error 
        appearance="secondary"
        class="ui-error-nl"
        :header="false"
        subtitle="De aangevraagde dienst is niet gekoppeld aan een catalogus."
        title="Geen catalogus"
        />
      <ui-error 
        appearance="secondary"
        class="ui-error-fr"
        :header="false"
        subtitle="Le service demandé n'est pas lié à un catalogue."
        title="Pas de catalogue"
        />
    </div>
  </div>
</template>

<script>
import UiError from '@/components/ui/error'

export default {
  name: 'BootstrapErrorView',
  
  components: {
    UiError
  },

  inject: ['$localStorage'],

  mounted() {
    this.$localStorage.service = null;
  }
}
</script>

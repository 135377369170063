<template>
  <!-- Admin login popin -->
  <popins-modal
    :klass="{ 'popin-login': true, 'layout-modal': true }"
    @close="onHide"
    :visible="show && isEatin && isTableTop">

    <template v-slot:header>
      <h1 class="layout-modal__title">{{ $t('resto.restricted_area') }}</h1>
    </template>

    <forms-input
      autofocus
      :placeholder="$t('resto.admin_pin_placeholder')"
      @input="onGoToAdmin"
      v-model="adminPin"
    >{{$t('resto.admin_pin')}}</forms-input>

    <forms-label
      class="mb-xl"
      intent="danger"
      v-if="!hasTable"
    >{{ $t('resto.table_not_set')}}</forms-label>
  </popins-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { basil } from '@spices/basil'

export default {
  name: 'AdminLoginPopin',

  inject: ['$localStorage'],

  data() {
    return {
      show: false,
      adminPin: null,
    }
  },

  computed: {
    ...mapState({
      extensions: state => state.bootstrap.data.extensions,
      service: state => state['sayl-front.service'].service,
      _table: state => state['sayl-front.service-eatin'],
    }),

    ...mapGetters({
      isTableTop: 'bootstrap/isTableTop'
    }),

    isEatin(){
      return this.service && this.service.name === 'eatin'
    },

    pins() {
      let ret =  (!!this.extensions && !!this.extensions.oat) ? this.extensions.oat : [{ pin: '1020' }];
      return ret.map(r => r.pin || null).filter(r =>r!==null);
    },

    hasTable() {
      return !!this.table
    },

    table(){
      return basil.get(this._table, 'table')
    }
  },

  methods: {
    onHide() {
      if(this.hasTable) {
        this.show = false;
      }
    },

    onShow() {
      if(this.$route.name !== 'sayl-front-admin.admin') {
        this.show = true;
      }
    },

    onGoToAdmin() {
      if(this.pins.includes(this.adminPin)) {
        this.adminPin = null;
        this.$localStorage.admin = true
        this.show = false;
        this.$router.push({ name: 'sayl-front-admin.admin' }).catch(() => {});
      }
    }
  },

  mounted() {
    if(!this.hasTable && this.isTableTop) {
      this.show = true;
    }
  },

  created() {
    this.$bus.$on('admin:show-pin', () => {
      this.show = true;
    });
  }
}
</script>

<template>
  <!-- Admin login popin -->
  <popins-modal 
    :klass="{ 'popin-login': true, 'layout-modal': true }"
    :closable="false" 
    :visible="show">
    
    <template v-slot:header>
      <h1 class="layout-modal__title">{{ $t('resto.table_not_set') }}</h1>
    </template>
  </popins-modal>
</template>

<script>
export default {  
  data() {
    return { 
      show: false,
    }
  },

  mounted() {
    this.$bus.$on('table:null', (value) => {
      this.show = value;
    });
  }
}
</script>

export default (isMultipleShop = false) => {
  let prefix = !isMultipleShop ? 'single-shop' : 'multi-shop'
  let ret = []

  // Embed page
  ret.push({
    path: '/',
    component: () => import(`./${prefix}/shop`),
    meta: {
      bodyClass: 'view-catalog-shop'
    },
    children: [
      {
        path: 'b2b',
        name: 'sayl-front-catalog.b2b',
        component: () => import(`./${prefix}/b2b.vue`),
        meta: {
          bodyClass: 'view-catalog-b2b'
        }
      },
      isMultipleShop ? {
        path: ':service',
        name: 'sayl-front-catalog.service',
        redirect: { name: 'sayl-front-catalog.shops' },
        component: () => import(`./${prefix}/shops`),
        meta: {
          bodyClass: 'view-catalog-shops'
        }
      } : null,
      isMultipleShop ? {
        path: ':service/shops',
        name: 'sayl-front-catalog.shops',
        component: () => import(`./${prefix}/shops`),
        meta: {
          bodyClass: 'view-catalog-shops'
        }
      } : null,
      {
        path: isMultipleShop ? ':service/shop/:slug' : ':service',
        name: 'sayl-front-catalog.catalog',
        component: () => import(`./${prefix}/catalog`),
        meta: {
          bodyClass: 'view-catalog-list'
        },
        children: [
          {
            path: ':product',
            name: 'sayl-front-catalog.product',
            component: () => import(`./${prefix}/product`),
            meta: {
              bodyClass: '-no-scroll'
            }
          },
        ]
      },
      {
        path: '',
        name: 'sayl-front-catalog.services',
        component: () => import(`./${prefix}/services`),
        meta: {
          bodyClass: 'view-catalog-services'
        }
      },
    ].filter((c) => !basil.isNil(c))
  })

  return ret
}

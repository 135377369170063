import GenericView from './generic'

const routes = [
  {
    path: '/terms',
    name: 'sayl-front-cms.terms',
    component: GenericView,
    meta: {
      bodyClass: 'view-cms-generic',
      key: 'terms'
    }
  },
  {
    path: '/privacy',
    name: 'sayl-front-cms.privacy',
    component: GenericView,
    meta: {
      bodyClass: 'view-cms-generic',
      key: 'privacy'
    }
  },
  {
    path: '/cookie',
    name: 'sayl-front-cms.cookie',
    component: GenericView,
    meta: {
      bodyClass: 'view-cms-generic',
      key: 'cookie'
    }
  }
]


export default routes;

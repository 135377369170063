<template>
  <div class="app-loader__error">
    <ui-error 
      class="ui-error-en"
      subtitle="Sorry, the storefront you are looking for is currently not active."
      title="This webshop is not active"
      />
      
    <div class="group">
      <ui-error 
        appearance="secondary"
        class="ui-error-nl"
        :header="false"
        subtitle="Het spijt ons, maar de webshop die u wenst te bezoeker is momenteel inactief geplaatst"
        title="Deze webshop is niet actief"
        />
      <ui-error 
        appearance="secondary"
        class="ui-error-fr"
        :header="false"
        subtitle="La boutique en ligne que vous recherchez n'est pas active pour le moment"
        title="La boutique en ligne est inactive"
        />
    </div>

    <div class="group">
      <ui-error 
        appearance="secondary"
        class="ui-error-nl"
        :header="false"
        subtitle="Lo sentimos, el escaparate que está buscando no está activo actualmente"
        title="Esta tienda web no está activa"
        />
      <ui-error 
        appearance="secondary"
        class="ui-error-fr"
        :header="false"
        subtitle="Entschuldigung, die von Ihnen gesuchte Storefront ist derzeit nicht aktiv."
        title="Dieser Webshop ist nicht aktiv"
        />
    </div>
  </div>
</template>

<script>
import UiError from '@/components/ui/error'

export default {
  name: 'EmbedNotFoundErrorView',
  
  components: {
    UiError
  }
}
</script>

<template>
  <div class="app-loader__error">
    <ui-error 
      class="ui-error-en"
      subtitle="The requested table is inactive"
      title="Table inactive"
      />
      
    <div class="group">
      <ui-error 
        appearance="secondary"
        class="ui-error-nl"
        :header="false"
        subtitle="De aangevraagde tafel is inactief"
        title="Inactieve tafel"
        />
      <ui-error 
        appearance="secondary"
        class="ui-error-fr"
        :header="false"
        subtitle="La table demandée est inactive"
        title="Table inactive"
        />
    </div>
  </div>
</template>

<script>
import UiError from '@/components/ui/error'

export default {
  name: 'BootstrapErrorView',
  
  components: {
    UiError
  },

  inject: ['$localStorage'],

  mounted() {
    this.$localStorage.service = null;
  }
}
</script>

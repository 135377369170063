import { CoreController } from '@sayl/front-core'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import mitt from 'mitt'

import { Logger, Level } from '@spices/cayenne'
import { Anise, Field } from '@spices/anise'

import initApplication from './plugins/app'
import initBody from './plugins/body'
import initCP from './plugins/cp'
import initCore from './plugins/core'
import initGinger from './plugins/ginger'
//import initInjekt from './plugins/injekt'
import initModules from './plugins/modules'
import initReset from './plugins/reset'
import initObserver from './plugins/observer'
import badge from './plugins/badge'

import Error from '../helpers/error'
import { default as Config, isDevelopment } from '../config'

import eventbus from './eventbus'
import router from './router'
import store from './store'
import cp from './data/api/transports/cp'
import http from './data/api/transports/http'
import states from './states'

import i18n from './data/i18n/i18n'

const onError = (event) => {
  console.group('base:error')
  console.error(event)
  console.groupEnd('base:error')
}

//
// Error Management
//
window.addEventListener('error', onError)

let bootstrap = {};
bootstrap.install = (Vue) => {

  //
  // Badge
  //
  badge();

  //
  // Bugsnag
  //
  if (!isDevelopment()){
    Bugsnag.start({
      apiKey: 'ed9717410db504cd080349c271821b1f',
      plugins: [
        new BugsnagPluginVue(),
      ],
      appType: 'client',
      appVersion: Config.version.client,
      releaseStage: Config.env
    })
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
  }

  //
  // Args
  //
  let args = Anise.detect([
    // Cart
    new Field({
      name: 'cart',
      // persistent: true
    }),

    // Debug
    new Field({
      name: 'debug',
      fallback: 'off',
      persistent: true,
      search: /debug=(all|debug|info|warn|error)/
    }),

    // EmbedId
    // new Field({
    //   name: 'embed',
    //   path: /\/*([a-f\d]{24})\/*/,
    //   search: /embed=([a-f\d]{24})/
    // }),

    // Lang
    new Field({
      name: 'lang',
      // persistent: true,
      search: /lang=([\w]+)/,
    }),

    // Mode
    new Field({
      name: 'mode',
      persistent: true,
      search: /mode=(tt)/,
      values: ['tt']
    }),

    // nocache
    new Field({
      name: 'nocache',
      search: /nocache=([\w]+)/
    }),

    // Service
    new Field({
      name: 'service',
      path: /.?\/(delivery|eatin|pickup|virtual)\/?.?/,
      //persistent: true,
      search: /service=([\w]*)/,
      values: ['delivery', 'eatin', 'pickup', 'virtual']
    }),

    // Table
    new Field({
      name: 'table',
      // persistent: true,
      search: /table=([a-f\d]{24})/
    })
  ]);
  store.commit('bootstrap/setArgs', args)

  //
  // Logger
  //
  let logger = Logger.get()
  logger.level = Level.getFromName(args.debug.toUpperCase())
  logger.debug('args', args)
  Object.defineProperty(window, '$console', {
    get: () => logger
  })

  //
  // Transports
  //
  let transports = { cp, http }

  const emitter = mitt()

  if(window && !window.SaylRestoEmitter) {
    window.SaylRestoEmitter = emitter
  }
  //
  // Capacities
  //
  let capabilities = {
    args,
    eventbus,
    emitter,
    logger,
    router,
    states,
    store,
    transports,
    Vue
  }

  capabilities.i18n = new i18n({ locale: args.lang, transports, Vue })
  store.commit('bootstrap/setI18n', capabilities.i18n)
  capabilities.core = new CoreController(capabilities)

  //
  // Boostrap
  //
  Promise.resolve()
    .then(() => initApplication(capabilities))
    .then(() => initGinger(capabilities))
    .then(() => initCP(capabilities))
    .then(() => initCore(capabilities))
    .then(() => initModules(capabilities))
    //.then(() => initInjekt(capabilities))
    .then(() => initBody(capabilities))
    .then(() => initReset(capabilities))
    .then(() => initObserver(capabilities))
    .then(() => {
      store.dispatch('bootstrap/status', states.APPLICATION_COMPLETE)
      Config.state = states.APPLICATION_COMPLETE

      /*let func = () => {
        try {
          sayl.injekt.sp.apply('trackPageView')
        } catch(e) {
          setTimeout(() => func(), 250)
        }
      }

      func()*/
    })
    .catch((error) => {
      let e = error

      if (!!error && !error.hasOwnProperty('slug')){
        e = Error.BOOTSTRAP_ERROR
        e.error = Object.assign({}, error)

        Bugsnag.notify(error)
      }

      logger.error(error)
      store.dispatch('bootstrap/error', e)
    })
}

export default bootstrap

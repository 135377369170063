import VueBodyClass from '../router/body-class'

export default ({ logger, store, router }) => {
  return new Promise((resolve) => {
    logger.group('bootstrap.body');

    const routes = store.getters['ginger/getRoutes'];
    const bodyClass = new VueBodyClass(routes);

    // Initialization
    setTimeout(() => {
      let list = [];
      if (document.body.classList.contains('theme-askemma')) {
        list.push('theme-askemma');
      }
      if (document.body.classList.contains('-no-scroll')) {
        list.push('-no-scroll')
      }

      bodyClass.guard(router.currentRoute, () => { 
        document.body.classList.add(...list);
      });
    }, 500);

    // Route change
    router.beforeEach((to, from, next) => {
      bodyClass.guard(to, () => {
        let list = [];
        if (document.body.classList.contains('theme-askemma')){
          list.push('theme-askemma');
        }
        if (document.body.classList.contains('-no-scroll')){
          list.push('-no-scroll')
        }

        next();
        document.body.classList.add(...list);
      });
    })


    logger.groupEnd('bootstrap.body');
    resolve();
  })
}

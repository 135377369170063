import { basil } from '@spices/basil'
import { version as core } from '@sayl/front-core'
import { version as app } from '../package.json'
import { version as conn3ctAuthButton } from '@conn3ct/auth-button/package.json'

function getEnvValue(name, defaultValue){
  let ret = defaultValue // default value for the mutualised

  const isArgument = (e) => typeof e === 'object' && e !== null && e.toString() === '[object Arguments]'
  let dl = basil.get(window, 'dataLayer', []);
  let v = dl.find(e => {
    let ret = false

    if (isArgument(e) && (e.hasOwnProperty('length') && e.length == 2)) {
      let [n, value] = e
      ret = name === n
    }

    return ret
  })
  ret = basil.get(v, '[1]', ret)

  return ret
}

const config = {
  baseURL: [document.location.origin, process.env.VUE_APP_NAME || '/'].join('/'),
  cdn: process.env.VUE_APP_CDN || '/',
  originURL: getEnvValue('askemma.origin', `https://apps.sayl.cloud/askemma/${getEnvValue('askemma.embedId', null)}/`),
  embedId: getEnvValue('askemma.embedId', null),
  env: process.env.VUE_APP_ENV || 'production',
  injekt: {
    debug: process.env.VUE_APP_INJEKT_DEBUG || false,
    env: process.env.VUE_APP_INJEKT_ENV || 'production'
  },
  libs: process.env.VUE_APP_LIBS || '/',
  loyalty: {
    page: {
      url: process.env.VUE_APP_LOYALTY_PAGE_URL || 'https://cdn-apps.sayl.cloud/loyalty-page/production/latest/index.js'
    }
  },
  challenge: {
    page: {
      url: process.env.VUE_APP_CHALLENGE_PAGE_URL || 'https://cdn-apps.sayl.cloud/challenge-page/production/latest/index.js'
    }
  },
  publicPath: getEnvValue('askemma.publicPath', '/askemma/'),
  state: 0,
  transports: {
    cp: {
      api: process.env.VUE_APP_API,
      oauth: process.env.VUE_APP_OAUTH,
    },

    module: process.env.VUE_APP_MODULE,
    timeout: process.env.VUE_APP_TIMEOUT || 5000
  },
  version: {
    client: app,
    core: core,
    conn3ctAuthButton: conn3ctAuthButton
  },
  claimPageUrl: process.env.VUE_APP_CLAIM_URL || 'https://wallet.sayl.cloud/claims/'
};

const isDevelopment = () => config.env === 'development'
const isStaging = () => config.env === 'staging'
const isProduction = () => config.env === 'production'

if(isStaging()) {
  config.cdn = 'https://cdn-apps.sayl.cloud/staging/askemma/'
}
if(isDevelopment()) {
  config.cdn = '/';
}

export default config
export {
  isDevelopment,
  isProduction,
  isStaging,
}

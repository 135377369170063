import Routes from './views'
import Store from './store'

class Catalog {
  constructor() {
    this.fqn = 'front-catalog'
    this._isMultipleShop = false
  }

  get routes() {
    let ret = Routes(this._isMultipleShop)
    ret.forEach((route) => {
      if (!route.meta) {
        route.meta = {}
      }

      route.meta.module = this.fqn
    });

    return ret
  }

  get stores() {
    let ret = this._isMultipleShop ? [Store] : []
    return ret
  }

  install({ capacities, fqn }) {
    this.fqn = fqn
    this._isMultipleShop = capacities.store.state.bootstrap.isMultiShop
    this._store = capacities.store
  }
}

export default new Catalog()

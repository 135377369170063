import Bugsnag from '@bugsnag/js'
import Config from '@/config'
import cp from './transports/cp'

export default {
  authenticate() {
    let url = [Config.transports.cp.oauth, 'initialize'].join('');
    return cp.post(url, { redirect: window.location.href })
      .then((response) => {
        window.location = response.data.redirect
      }).catch((error) => {
        Bugsnag.notify(error)
        console.error('bootstrap.api.authenticate failed with message: ', error.message)
      })
  },

  bootstrap({ locale }) {
    let service = ['eatin', 'pickup', 'delivery'].find(s => document.location.pathname.includes('/' + s))
    if (service === 'pickup'){
      service = 'base'
    }

    let url = [Config.transports.cp.api, 'bootstrap'].join('')
    let args = ['convert=1']
    if (service){
      args.push(`service=${service}`)
    }
    if (locale){
      args.push(`locale=${locale.iso}`)
    }
    args = args.join('&')
    url = [url, args].join('?')

    let timeout = setTimeout(() => Bugsnag.notify(new Error('It took more than 5 seconds to load the bootstrap.')), 5000)
    let boots = window.sayl_bootstrap || null

    return new Promise((resolve, reject) => {
      if(boots == null) {
        cp.get(url /*, {timeout: Config.transports.timeout}*/)
          .then(response => {
            timeout != null && clearTimeout(timeout)
            let data = response.data

            cp.defaults.baseURL = Config.transports.cp.api
            cp.defaults.headers.common['X-CSRF-TOKEN'] = data.csrf_token

            resolve(response)
          })
          .catch(e => reject(e))
      } else {
        timeout != null && clearTimeout(timeout)
        cp.defaults.baseURL = Config.transports.cp.api
        cp.defaults.headers.common['X-CSRF-TOKEN'] = boots.csrf_token
        resolve({ data: boots })
      }
    })
  },

  keepAlive() {
    cp.get('keepalive')
      .then((response) => {
        cp.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrf_token
      }).catch((error) => {
        if(error.message != 'Network Error' && error instanceof Error) {
          Bugsnag.notify(error)
        }
        console.error('bootstrap.api.keepalive errored with message:', error.message)
      });
  },

  logout() {
    let url = [Config.transports.cp.oauth, 'forget'].join('');
    return oauth.post(url)
      .then(() => document.location = '/')
      .catch((error) => {
        console.error('bootstrap.api.logout errored with message:', error.message)
      })
  },

  reset() {
    return new Promise((resolve, reject) => {
      let url = ['bootstrap', '?convert=1&reset=1'].join('');
      cp.get(url)
        .then(response => {
          let data = response.data

          cp.defaults.baseURL = Config.transports.cp.api
          cp.defaults.headers.common['X-CSRF-TOKEN'] = data.csrf_token

          resolve(response)
        })
        .catch(e => reject(e))
    })
  }
}

<template>
  <layout-modal
    :classes="classes"
    v-bind="attrs"
    v-on="listeners">

    <!-- Header -->
    <header class="modal-age-disclaimer__header">
      <div class="modal-age-disclaimer__headings">
        <h2 class="modal-age-disclaimer__title">{{ $t('resto.age_disclaimer_title') }}</h2>
      </div>
    </header>

    <div
      class="modal-age-disclaimer__content"
      v-html="disclaimer"
    ></div>

    <!-- Footer -->
    <div class="modal-age-disclaimer__footer">
      <actions-button
        :appearance="$pepper.Appearance.DEFAULT"
        :size="$pepper.Size.S"
        @click="onLeave"
      >{{ $t('resto.age_disclaimer_leave') }}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        :size="$pepper.Size.S"
        @click="onConfirm"
      >{{ $t('resto.age_disclaimer_confirm') }}</actions-button>
    </div>
  </layout-modal>
</template>

<script>
import LayoutModal from '@/components/layouts/modal'

export default {
  name: 'ModalAgeDisclaimer',

  components: {
    LayoutModal
  },

  inject: ['$embed', '$user'],

  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    attrs() {
      return {
        closable: false,
        visible: this.visible,
        size: 's',
        position: 'middle-center',
      }
    },

    classes() {
      return {
        'modal-age-disclaimer': true
      }
    },

    disclaimer() {
      let all = this.$basil.get(this.$embed, 'embed.model.ageDisclaimerText.all')
      let lang = this.$basil.get(this.$user, 'user.lang')
      let text = this.$basil.get(this.$embed, `embed.model.ageDisclaimerText.${lang}`, all)

      return text
    },

    listeners() {
      return {
        back: this.back,
      }
    },
  },

  watch: {
    visible: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal === true && oldVal === false) {
          this.reset()
        }
      },
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    onConfirm() {
      window.sessionStorage.setItem('ageDisclaimer', true)
      this.$emit('confirm')
    },

    onLeave() {
      window.location.href = 'https://saylcloud.com'
    },
  },
}
</script>

<template>
  <transition
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave">

    <aside
      v-show="visible"
      :class="classes">

      <div class="n-burger__content">
        <!-- Header -->
        <header class="n-burger__header">
          <ui-img
            :src="logo"
            alt="Logo"
            class="n-burger__logo"
            v-if="logo"
          />

          <div class="n-burger__cactions">
            <button
              class="n-burger__close"
              @click="close">
              <span></span>
              <span></span>
            </button>
          </div>
        </header>

        <!-- Body -->
        <div class="n-burger__body">
          <ul class="n-burger__list">
            <!-- Menu -->
            <li
              v-if="service"
              class="n-burger__item">
              <router-link
                :to="{ name: 'sayl-front-catalog.catalog', params: { embed: embed, service: service }}"
                class="n-burger__link n-header__burger-bag">
                <i :class="`icon-${service} n-burger__icon`"></i>
                <div class="n-burger__item-label">
                  <h2 class="title">{{ $t('resto.burger_menu') }}</h2>
                  <p class="description">{{ $t('resto.burger_menu_description', {service: serviceTranslated }) }}</p>
                </div>
              </router-link>
            </li>

            <!-- Cart -->
            <li
              class="n-burger__item"
              v-if="!isCatalogReadonly">
              <router-link
                :to="{ name: 'sayl-front-checkout.cart' }"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-bag n-burger__icon"></i>
                <div class="n-burger__item-label">
                  <h2 class="title">{{ $t('resto.burger_your_cart') }}</h2>
                  <p class="description">{{ $t('resto.burger_your_cart_description', { count: $basil.get(cart, 'items.length'), price: cartPrice, }) }}</p>
                </div>
              </router-link>
            </li>

            <!-- Loyalty -->
            <li
              class="n-burger__item"
              v-if="showLoyalty">
              <router-link
                :to="{ name: 'sayl-front-user.loyalty' }"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-award n-burger__icon"></i>
                <div class="n-burger__item-label">
                  <h2 class="title">{{ $t('resto.burger_card_loyalty') }}</h2>
                  <p class="description">{{ $t('resto.burger_card_loyalty_description') }}</p>
                </div>
              </router-link>
            </li>

            <!-- Challenges -->
            <li
              class="n-burger__item"
              v-if="!isCatalogReadonly && hasChallenges && !isModeTt">
              <router-link
                :to="{ name: 'sayl-front.challenges' }"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-flag n-burger__icon"></i>
                <div class="n-burger__item-label">
                  <h2 class="title">{{ $t('resto.burger_challenges') }}</h2>
                  <p class="description">{{ $t('resto.burger_challenges_description') }}</p>
                </div>
              </router-link>
            </li>

            <!-- User -->
            <li
              class="n-burger__item"
              v-if="isLogged && !isCatalogReadonly && !isModeTt">
              <router-link
                :to="{ name: 'sayl-front-user.home' }"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-user n-burger__icon"></i>
                <div class="n-burger__item-label">
                  <h2 class="title">{{ userName }}</h2>
                  <p class="description">{{ $t('resto.burger_user_description') }}</p>
                </div>
              </router-link>
            </li>

            <!-- User -->
            <li
              class="n-burger__item"
              v-if="!isLogged && !isCatalogReadonly && !isModeTt">
              <router-link
                :to="{ name: 'sayl-front-user.home' }"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-user n-burger__icon"></i>
                <div class="n-burger__item-label">
                  <h2 class="title">{{ $t('resto.burger_login') }}</h2>
                  <p class="description">{{ $t('resto.burger_login_description') }}</p>
                </div>
              </router-link>
            </li>

            <!-- Company -->
            <li
              class="n-burger__item"
              v-if="!isLogged && selectedCompany">
              <a
                @click.prevent="onCompanyChange"
                href="#"
                :appearance="$pepper.Appearance.LINK"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-briefcase n-burger__icon"></i>
                <div class="n-burger__item-label">
                  <h2 class="title">{{ $t('resto.burger_company') }}</h2>
                  <p class="description">{{ $t('resto.burger_company_description') }}</p>
                </div>
              </a>
            </li>

            <!-- Admin actions -->
            <li
              v-for="item in admin"
              :key="item.label && !isCatalogReadonly"
              class="n-burger__item">
              <actions-button
                appearance="subtle"
                class="n-burger__link"
                @click="onShowAdminPin"
              >{{ item.label }}</actions-button>
            </li>
          </ul>
        </div>

        <!-- Footer -->
        <footer class="n-burger__footer">
          <div
            class="n-burger__footerrow logout"
            v-if="isLogged">
            <div
              appearance="subtle"
              class="footer__link n-burger__link n-header__burger-bag"
              @click="onLogout">
              <i :class="`icon-logout n-burger__icon`"></i>
              <div class="n-burger__item-label">
                <h2 class="title">{{ $t('resto.burger_logout') }}</h2>
                <p class="description">{{ $t('resto.burger_logout_description') }}</p>
              </div>
            </div>
          </div>

          <div class="n-burger__footerrow legal">
            <div class="legal__content">
              <forms-select
                class="n-burger__langs"
                :placeholder="$t('resto.burger_lang_placeholder')"
                :options="langs"
                :value="userLang"
                @change="onChangeLang"
              />

              <ul class="n-burger__legal">
                <li
                  v-for="item in legal"
                  :key="item.label"
                  class="n-burger__legalitem">
                  <a
                    :href="item.href"
                    target="_blank"
                    rel="nofollow"
                  >{{ item.label }}</a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>

      <!-- Blanket -->
      <popins-blanket
        ref="blanket"
        :disabled="false"
        @click="close"
        :visible="visible"
      />
    </aside>
  </transition>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex'

import UiImg from '../ui/img'
import LayoutModal from '../layouts/modal'
import MixinCurrency from '@/helpers/currency'

import Config from '@/config'

export default {
  name: 'SaylFrontBurger',

  components: {
    LayoutModal,
    UiImg,
  },

  inject: [
    '$core',
    '$embed',
    '$localStorage',
    '$loyalty',
    '$user',
  ],

  mixins: [
    MixinCurrency
  ],

  data(){
    return {
      showAdminModal: false,
      adminPin: null
    }
  },

  computed: {
    ...mapState({
      cart: state => state['sayl-front.cart'].cart,
      visible: state => state.bootstrap.burger,
      locales: state => state.bootstrap.i18n.locales,
      i18n: state => state.bootstrap.i18n,
      service: state => state['sayl-front.service'].service,
    }),

    ...mapGetters({
      oat: 'bootstrap/oat',
    }),

    actions() {
      return [
        { label: this.$t('resto.burder_actions_login'), href: {name:'sayl-front-catalog.catalog'} },
        { label: this.$t('resto.burger_actions_my_orders'), href: {name:'sayl-front-catalog.catalog'} },
      ];
    },

    hasEatinRewards() {
      return this.hasLoyalty && this.$basil.get(this.$loyalty, 'program.rewardEatin', false)
    },

    admin() {
      return this.$localStorage.mode === 'tt' ? [{ label: this.$t('resto.burger_actions_admin'), href: {name:'sayl-front-admin.admin'} }] : [];
    },

    cartPrice() {
      return this.toCurrency(this.$basil.get(this, 'cart.computedTotal', 0))
    },

    classes() {
      return {
        'n-burger': true,
        '-is-active': true
      }
    },

    embed() {
      return this.$localStorage.embed || this.$route.params.embed;
    },

    hasChallenges() {
      return this.$basil.get(this.oat, 'is_challenge_enabled', false)
    },

    hasLoyalty() {
      let excludedShopIds = this.$basil.get(this.$loyalty, 'program.excludedShopIds', [])
      let isShopExcluded = false
      
      if(!this.$basil.isNil(excludedShopIds) && !this.$basil.isEmpty(excludedShopIds)) {
        isShopExcluded = !this.$basil.isNil(excludedShopIds.find(e => e === this.$basil.get(this.$embed, 'shop.model.id')))
      }

      return [
        !this.$basil.isNil(this.$basil.get(this.$loyalty, 'program', null)),
        !this.isModeTt,
        !isShopExcluded
      ].filter(r => r === false).length === 0
    },

    isCatalogReadonly() {
      return this.$basil.get(this.oat, 'is_catalog_readonly', false)
    },

    isLogged() {
      return !this.$basil.get(this.user, 'anonymous', true)
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin'
    },

    langs() {
      return this.locales.map((l) => {
        return {
          label: this.$t(`resto.lang_${l.iso.toLowerCase()}`),
          value: l.iso
        }
      })
    },

    legal() {
      return [
        { label: this.$t('resto.burger_legal_term_and_conditions'), href: this.$t('resto.burger_legal_term_and_conditions_link') },
        { label: this.$t('resto.burger_legal_privacy_policy'), href: this.$t('resto.burger_legal_privacy_policy_link') },
      ]
    },

    logo() {
      return this.$basil.get(this.shop, 'logo', null)
    },

    service() {
      return this.$localStorage.service || this.$route.params.service;
    },

    serviceTranslated() {
      return this.$t('resto.' + this.service === 'pickup' ? 'base' : this.service)
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    showLoyalty() {
      let t = (this.$loyalty?.program?.pageActive ?? false) ? true : !this.isLogged
      return !this.isCatalogReadonly && this.hasLoyalty && t && !this.isModeTt //&& this.hasEatinRewards
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userLang() {
      let ret = this.user != null ?
                this.locales.find(l => l.lang === this.user.lang) :
                this.locales.find(l => l.lang ===  this.shop.primary_language)

      return this.$basil.get(ret, 'iso', null)
    },

    userName() {
      let firstname = this.$basil.get(this, 'user.firstname')
      let lastname = this.$basil.get(this, 'user.lastname')

      return firstname + ' ' + lastname
    },

    selectedCompany() {
      return !!this.$localStorage.company
    }
  },

  watch: {
    burger(value) {
      value ? this.show() : this.hide()
    },
  },

  methods: {
    ...mapActions({
      changeLang: 'sayl-front.user/changeLang'
    }),

    close(){
      this.$store.dispatch('bootstrap/burger', false)
    },

    onLogout() {
      let logout = () => {
        this.$user.reset()
          .then(() => this.$router.push({ name: 'sayl-front-catalog.services' }).catch(() => {}))
          .catch((e) => $console.error(e))
          .finally(() => this.close())
      }

      this.$store.dispatch('bootstrap/burger', false)

      this.$confirm({
        title: this.$t('resto.logout_burger_confirmation_title'),
        description: this.$t('resto.logout_burger_confirmation_description'),
        onPrimary: logout
      })
    },

    //
    // Transitions
    //

    afterEnter(el) {
      this.active = true
    },

    beforeLeave(el) {
      this.active = false
    },

    delay(delay) {
      return new Promise(function(resolve) {
        setTimeout(resolve, delay)
      })
    },

    enter(el, done) {
      this.delay(500).then(() => { done() })
    },

    leave(el, done) {
      this.delay(500).then(() => { done() })
    },

    show() {
      document.body.classList.add('-no-scroll')
    },

    hide() {
      document.body.classList.remove('-no-scroll')
    },

    onChangeLang(value) {
      this.showLocales = false;
      this.$core.changeLang({ locale: value })
        .catch((e) => $console.error(e))
    },

    onCompanyChange() {
      this.$localStorage.company = null
      window.location.assign(Config.originURL + 'b2b')
    },

    onShowAdminPin() {
      this.close();
      this.$bus.$emit('admin:show-pin')
    }
  },
}
</script>

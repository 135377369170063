import { mapState } from 'vuex';
<template>
  <popins-modal
    closable
    @close="onClose"
    :visible="visible"
    v-if="visible">
    <div class="modal-idle">
      <h1 class="modal-idle__title">{{ $t('resto.idle_title') }}</h1>
      <p class="modal-idle__description">{{ $t('resto.idle_description') }}</p>

      <footer class="modal-idle__footer">
        <actions-button
          appearance="primary"
          @click="onClose"
        >{{ $t('resto.action_confirm') }}</actions-button>
      </footer>
    </div>
  </popins-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ModalCustom',

  inject: [
    '$user',
    '$core',
    '$localStorage',
    '$embed'
  ],

  data() {
    return {
      visible: false
    }
  },

  computed: {
    ...mapState({
      shops: state => state.bootstrap.data.extensions.oat,
    }),

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    isMultiShopsEnabled() {
      let shop = this.shops?.find(s => s.shop_id === this.embed.shopId)

      return shop?.is_multi_shops_enabled ?? false
    },
  },

  methods: {
    onClose() {
      this.visible = false;
    }
  },

  mounted() {
    this.$bus.$on('idle-timeout', () => {
      this.visible = true;
    });

    this.$bus.$on('reset-user', () => {
      this.$user.reset()
        .then(() => this.$core.initCart({
          fresh: true,
          service: this.$localStorage.service,
          enrich: this.isMultiShopsEnabled ? 1 : 0
        }))
        .then(() => {
          this.$router.push({ name: 'sayl-front-catalog.catalog', params: { service: this.$localStorage.service } })
            .catch((e) => {})
          this.visible = false
        })
        .catch((e) => $console.error(e))
    });
  },

  beforeDestroy() {
    this.$bus.$off('popin')
    this.$bus.$off('reset-user')
  }
}
</script>

import { basil } from '@spices/basil'

export default {
  name: 'checkout',

  actions: {
    reset: ({ state }) => {
      state.auth = {
        active: true,
        errors: {},
        loading: false,
        name: 'auth',
        pristine: true,
        required: false,
        valid: false,
      };

      state.delivery = {
        active: true,
        errors: [],
        loading: false,
        name: 'delivery',
        pristine: true,
        required: false,
        valid: false,
      };

      state.invoice = {
        active: true,
        errors: [],
        loading: false,
        name: 'invoice',
        pristine: true,
        required: false,
        valid: false,
      };

      state.paymentMethod = {
        active: false,
        errors: [],
        loading: false,
        name: 'paymentMethods',
        pristine: true,
        required: false,
        valid: false,
      };

      state.term = {
        active: true,
        errors: [],
        loading: false,
        name: 'term',
        pristine: true,
        required: false,
        valid: false,
      };

      state.timeslot = {
        active: true,
        errors: [],
        loading: false,
        name: 'timeslot',
        pristine: true,
        required: false,
        valid: false,
      };

      state.voucher = {
        active: false,
        errors: [],
        loading: false,
        name: 'voucher',
        pristine: true,
        required: false,
        valid: true,
      };

      state.saylWallet = {
        active: false,
        errors: [],
        loading: false,
        name: 'saylWallet',
        pristine: true,
        required: false,
        valid: true,
      };
    }
  },

  state: {
    auth: {
      active: true,
      errors: {},
      loading: false,
      name: 'auth',
      pristine: true,
      required: false,
      valid: false,
    },

    delivery: {
      active: true,
      errors: [],
      loading: false,
      name: 'delivery',
      pristine: true,
      required: false,
      valid: false,
    },

    invoice: {
      active: true,
      errors: [],
      loading: false,
      name: 'invoice',
      pristine: true,
      required: false,
      valid: false,
    },

    loyalty: {
      active: true,
      errors: [],
      loading: false,
      name: 'loyalty',
      pristine: true,
      required: false,
      valid: true,
    },

    paymentMethod: {
      active: false,
      errors: [],
      loading: false,
      name: 'paymentMethods',
      pristine: true,
      required: false,
      valid: false,
    },

    teaser: {
      loyalty: {
        active: false,
        errors: [],
        loading: false,
        name: 'teaser.loyalty',
        pristine: true,
        required: false,
        valid: true,
        type: 'loyalty'
      },

      wallet: {
        active: false,
        errors: [],
        loading: false,
        name: 'teaser.wallet',
        pristine: true,
        required: false,
        valid: true,
        type: 'wallet'
      },
    },

    term: {
      active: true,
      errors: [],
      loading: false,
      name: 'term',
      pristine: true,
      required: false,
      valid: false,
    },

    timeslot: {
      active: true,
      errors: [],
      loading: false,
      name: 'timeslot',
      pristine: true,
      required: false,
      valid: false,
    },

    voucher: {
      active: false,
      errors: [],
      loading: false,
      name: 'voucher',
      pristine: true,
      required: false,
      valid: false,
    },

    saylWallet: {
      active: false,
      errors: [],
      loading: false,
      name: 'wallet',
      pristine: true,
      required: false,
      valid: false,
    },
  },

  getters: {
    actives: (state, getters, rootState) => {
      let cards = [ state.auth, state.paymentMethod, state.voucher, state.saylWallet ]
      // if (isDelivery){
      //   cards.push(state.delivery)
      // }

      let service = basil.get(rootState, "['sayl-front.service'].service.name", '')

      if(service !== 'eatin' && service !== 'virtual') {
        cards.push(state.timeslot)
      }

      let pm = basil.get(rootState, "['sayl-front.checkout'].paymentMethod", null)
      let excludeVoucher = true;

      if(pm && pm.slug && pm.slug === 'invoice') {
        excludeVoucher = !basil.get(pm, 'metadata.requires_voucher', false)
      }

      return cards.filter(e => {
        let ret = e.active === true && e.name !== 'voucher'

        if(!excludeVoucher) {
          ret = e.active === true || e.valid === false
          if(e.valid === false) {
            state.voucher.errors = ['sayl-front.voucher_is_required_for_invoice']
          }
        }

        return ret
      }).map(e => e.name)
    },

    isOneActive: (state, getters) => getters.actives.length > 0
  },

  mutations: {
    setActive: (state, { card, value }) => state[card].active = value,
    setErrors: (state, { card, values }) => state[card].errors = values,
    setLoading: (state, { card, value }) => state[card].loading = value,
    setPristine: (state, { card, value }) => state[card].pristine = value,
    setValid: (state, { card, value }) => state[card].valid = value,
  }
}

import Axios from 'axios'

const cp = Axios.create({
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
});

let cpt = 0;

const response = (response) => {
  cpt = 0;
  return response
};

const error = (error) => {
  let ret = {
    status: null,
    _items: {}
  }

  if(error && error.response && error.response.status) {
    ret.status = error.response.status
  }

  if(cpt < 2 && ([502, 504, 501].includes(ret.status))) {
    cpt++
    return cp.request(error.config)
  } else {
    cpt = 0
  }

  if(ret.status === 422) {
    if(error && error.response && error.response.data && error.response.data.errors) {
      ret._data = error.response.data
      ret._items = error.response.data.errors
    } else if(error && error.response && error.response.data && error.response.data.error && error.response.data.error.dataPointer) {
      ret._items[error.response.data.error.dataPointer.join('.')] = ['field_is_required'];
    }
  } else if(ret.status === 400) {
    if(error && error.response && error.response.data && error.response.data.message) {
      ret._items = { global: [error.response.data.message] }
    } else {
      ret._items = { global: ['bad_request'] }
    }
  } else if(ret.status === 404) {
    ret._items = { global: ['not_found'] }
  } else if(ret.status === 500) {
    ret._items = { global: ['server_error'] }
  } else {
    ret._items = error
  }

  ret.get = (field)=> {
    return ret._items.hasOwnProperty(field) ?
        ret._items[field] : []
  }

  return [422, 400, 404].includes(ret.status) ? Promise.reject(ret) : Promise.reject(error)
}

cp.interceptors.response.use(response, error);

export default cp

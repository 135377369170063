import Routes from './views'

class User {
  constructor() {
    this.fqn = 'front-user'
  }

  get routes() {
    let ret = Routes();
    ret.forEach((route) => {
      if (!route.meta) {
        route.meta = {}
      }

      route.meta.module = this.fqn;
    });

    return ret;
  }

  get stores() {
    return [];
  }

  install({ fqn }) {
    this.fqn = fqn;
  }
}

export default new User()
